// @flow
import { JSSThemeVariables } from '../JSSThemeDefault';

export const JSSThemeOryx10 = (themeVars: typeof JSSThemeVariables) => {
  const {
    colors: {
      colorTypes: { background1, white },
    },
  } = themeVars;

  return {
    OryxMobile: {
      toggle: {
        color: white,
        backgroundColor: background1,
      },
    },
  };
};
