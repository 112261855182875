// @flow
import { type JSSThemeDefaultBonusesType } from '../JSSThemeDefaultBonuses';

export const JSSThemeBonuses10: JSSThemeDefaultBonusesType = (themeVars) => {
  const {
    margins,
    colors: {
      colorCombinations: { whitePrimary, whiteAccent },
      colorTypes: { background2, background3, primary, text5 },
    },
  } = themeVars;
  return {
    BonusesTabs: {
      tabsItemDesktop: {
        ...whitePrimary,
        transition: '.3s ease-in',
        fontWeight: 'bold',
        '&:hover, &.active': {
          background: text5,
          color: 'white',
        },
      },
      tabsItemMobile: {
        color: '#000',
        background: background3,
        borderRight: `1px solid ${background2}`,
        fontWeight: 'bold',
        '&.active': {
          ...whiteAccent,
        },
      },
      root: {
        padding: [margins.md, margins.xm],
      },
      tabsContentDesktop: {
        padding: [margins.md, 0],
      },
      bonusContainer: {
        background: 'transparent',
        border: 'none',
        padding: [margins.md, 0],
      },
    },
    Bonuses: {
      removeButton: {
        background: primary,
        '&:hover': {
          background: text5,
        },
      },
      root: {
        background: background3,
        border: 'none',
        borderRadius: 5,
      },
      PickButton: {
        background: primary,
        '&:hover': {
          background: text5,
        },
      },
    },
  };
};
