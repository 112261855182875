// @flow
import {
  type JSSThemeShapeType,
  type JSSThemeVariablesShapeType,
  type ColorTypes,
  getColorCombinations,
  type GetJSSThemePatternsType,
  colorTypes,
} from '../JSSThemeDefault';
import { getButtonColors } from '../jss-helpers';
import { JSSThemeOutcomes10 } from './JSSThemeOutcomes10';
import { JSSThemeSportsMenu10 } from './JSSThemeSportsMenu10';
import { JSSThemeHeaderMobile10 } from './JSSThemeHeaderMobile10';
import { JSSThemeHeader10 } from './JSSThemeHeader10';
import { JSSThemeHeaderMenu10 } from './JSSThemeHeaderMenu10';
import { JSSThemeCoupons10 } from './JSSThemeCoupons10';
import { JSSThemeForm10 } from './JSSThemeForm10';
import { JSSThemeAuthForm10 } from './JSSThemeAuthForm10';
import { JSSThemeBalance10 } from './JSSThemeBalance10';
import { JSSThemeBonuses10 } from './JSSThemeBonuses10';
import { JSSThemeBetslip10 } from './JSSThemeBetslip10';
import { JSSThemePromotions10 } from './JSSThemePromotions10';
import { JSSThemeSitemap10 } from './JSSThemeSitemap10';
import { JSSThemeMyBets10 } from './JSSThemeMyBets10';
import { JSSThemeCasino10 } from './JSSThemeCasino10';
import { JSSThemeBannerItems10 } from './JSSThemeBannerItems10';
import { JSSThemeSearch10 } from './JSSThemeSearch10';
import { JSSThemePopularLeagues10 } from './JSSThemePopularLeagues10';
import { JSSThemeAccountSection10 } from './JSSThemeAccountSection10';
import { JSSThemeLive10 } from './JSSThemeLive10';
import { JSSThemeOryx10 } from './JSSThemeOryx10';
import { JSSThemeBetgenius10 } from './JSSThemeBetgenius10';

export const colorTypes10: ColorTypes = {
  ...colorTypes,
  primary: '#17A34A',
  text1: '#1a1a1a',
  text2: '#4E5A6C',
  text3: '',
  text4: '#377440', //available for a new color
  text5: '#188b43', //available for a new color
  background1: '#0D4B24',
  background2: '#C1D0C8',
  background3: '#ECECEC',
  background4: '', //available for a new color
  background5: '', //available for a new color
  background6: '', //available for a new color
  shadow: 'rgba(0, 0, 0, 0.3)', //black whith opacity for text shadow
  accent: '',
  success: '#21a22d',
  error: '#FF5C5C',
  error2: '#ff3434',
  error3: '#ff3434',
  warning: '#fece38',
  inform: '#fece38',
  neutral1: '#f3f3f3',
  neutral2: '',
  neutral3: '',
  neutral4: '', //available for a new color
  border1: '#eee',
  border2: '#c6c6c6',
};

const pick = ({
  text2Primary,
  blackBg3,
  whiteText1,
  whitePrimary,
  whiteBg1,
  text1White,
  text1Bg2,
  whiteBg2,
  whiteError,
  text1Bg3,
}) => ({
  text2Primary,
  blackBg3,
  whiteText1,
  whitePrimary,
  whiteBg1,
  text1White,
  text1Bg2,
  whiteBg2,
  whiteError,
  text1Bg3,
});

export const colorCombinations10 = getColorCombinations(colorTypes10, pick);

export const JSSThemeVariables10: JSSThemeVariablesShapeType = {
  colors: {
    colorCombinations: colorCombinations10,
    colorTypes: colorTypes10,
  },
  fontSizes: {
    xxxl: 36,
  },
};

export const JSSTheme10: JSSThemeShapeType = (themeVars) => {
  const {
    margins,
    fontSizes,
    colors: {
      colorCombinations: { whiteAccent, whiteBg1, whitePrimary },
      colorTypes: { white, primary, background1, background2, text1 },
    },
  } = themeVars;
  return {
    Heading: {
      common: {
        background: background1,
        color: white,
      },
    },
    Button: {
      default: getButtonColors(whiteAccent),
      primary: getButtonColors(whitePrimary),
      secondary: getButtonColors(whiteBg1),
      accent: getButtonColors(whiteAccent),
      linkAccent: {
        color: white,
        opacity: 0.7,
        '&:hover': {
          opacity: 1,
          color: white,
        },
      },
      large: {
        fontSize: fontSizes.xxl,
        padding: [margins.md, margins.xm],
      },
      small: {
        padding: [margins.xs, margins.md],
      },
    },
    Geoblock: {
      logoGeoblock: {
        background: primary,
      },
    },
    header: JSSThemeHeader10(themeVars),
    headerMenu: JSSThemeHeaderMenu10(themeVars),
    outcomes: JSSThemeOutcomes10(themeVars),
    sportsMenu: JSSThemeSportsMenu10(themeVars),
    headerMobile: JSSThemeHeaderMobile10(themeVars),
    coupons: JSSThemeCoupons10(themeVars),
    form: JSSThemeForm10(themeVars),
    authForm: JSSThemeAuthForm10(themeVars),
    balance: JSSThemeBalance10(themeVars),
    bonuses: JSSThemeBonuses10(themeVars),
    betslip: JSSThemeBetslip10(themeVars),
    mybets: JSSThemeMyBets10(themeVars),
    casino: JSSThemeCasino10(themeVars),
    popularLeagues: JSSThemePopularLeagues10(themeVars),
    banners: JSSThemeBannerItems10(themeVars),
    accountSection: JSSThemeAccountSection10(themeVars),
    promotions: JSSThemePromotions10(themeVars),
    sitemap: JSSThemeSitemap10(themeVars),
    oryx: JSSThemeOryx10(themeVars),
    betgenius: JSSThemeBetgenius10(themeVars),
    BetslipMyBetsPanelTitle: {
      heading: {
        ...whitePrimary,
      },
    },
    BetslipMyBetsPanelUI: {
      singleSelectionWrapper: {
        borderTop: `1px solid ${white}`,
      },
      panel: {
        background: white,
      },
    },
    BetslipMyBetsStakePotentialTotalUI: {
      stakePotentialWrapper: {
        color: text1,
        borderTop: `1px solid ${background2}`,
      },
      stakePotential: {
        color: text1,
      },
    },
    NoBetsMessage: {
      root: { ...whiteBg1, fontWeight: 'normal' },
    },
    LiveCalendar: {
      common: {
        margin: 0,
        padding: margins.md,
      },
      h2: {
        color: text1,
        background: 'transparent',
        fontSize: fontSizes.lg,
      },
      dateTitle: {
        fontSize: fontSizes.md,
        color: text1,
        background: 'transparent',
      },
      dateEvents: {
        margin: 0,
      },
      dateEvent: {
        // borderBottom: `1px solid ${border1}`,
        color: text1,
        background: 'transparent',
        '&:hover': {
          color: text1,
          background: background2,
        },
      },
      link: {
        color: text1,
      },
      dateEventNoMarkets: {
        '&:hover': {
          cursor: 'default',
        },
      },
      dateEventIcon: {},
      icon: {
        fontSize: fontSizes.lg,
        marginRight: margins.md,
      },
    },
    search: JSSThemeSearch10(themeVars),
    live: JSSThemeLive10(themeVars),
  };
};

export const getJSSThemePatterns10: GetJSSThemePatternsType = (themeVars) => {
  const {
    colors: {
      colorCombinations: { whitePrimary, whiteAccent },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    buttons: {
      primary: getButtonColors(whitePrimary),
      secondary: getButtonColors(whitePrimary),
      accent: getButtonColors(whiteAccent),
      large: {
        lineHeight: '38px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
    },
  };
};
