// @flow
import { type JSSThemeDefaultCouponsType } from '../JSSThemeDefaultCoupons';

export const JSSThemeCoupons10: JSSThemeDefaultCouponsType = (themeVars) => {
  const {
    borderRadius,
    margins,
    colors: {
      colorTypes: { neutral3, white, background1, text1, background2, primary },
    },
  } = themeVars;
  return {
    MobileWidgetCouponView: {
      sportHeader: {
        '&:first-child': {
          marginTop: 0,
        },
      },

      sportsContainer: {
        background: 'white',
        borderRadius: [borderRadius.xl, borderRadius.xl, 0, 0],
        overflow: 'hidden',
      },
    },
    AllLeagues: {
      searchInput: {
        border: `1px solid ${text1}`,
      },
    },
    SportTabs: {
      tabs: {
        background: background1,
        padding: 0,
      },
      tab: {
        background: background1,
        borderRadius: 0,
        color: background2,
        marginRight: 0,
        padding: margins.md,
        '&:hover': {
          color: background2,
        },
      },
      activeTab: {
        background: white,
        color: text1,
        borderRadius: [borderRadius.xl, borderRadius.xl, 0, 0],
        '&:focus': {
          color: text1,
        },
        '&:hover': {
          color: text1,
        },
      },
    },
    EventPrimaryMarketOutcomesMobile: {
      teams: {
        color: neutral3,
      },
      teamItem: {
        color: neutral3,
      },
      date: {
        color: neutral3,
      },
    },
    Market: {
      root: {
        borderTop: 'none',
      },
    },
    WidgetSport: {
      sport: {
        padding: [0, margins.lg],
      },
    },
    OutcomesCounter: {
      outcomeCount: {
        background: background2,
        fontWeight: 'bold',
        '&:hover': {
          background: primary,
          color: '#fff',
        },
      },
    },
    Betbuilder: {
      exampleContainer: {
        background: white,
        '@media (pointer: coarse)': {
          padding: [0, 10],
        },
      },
    },
  };
};
