// @flow
import { type JSSThemeDefaultAuthFormType } from '../JSSThemeDefaultAuthForm';

export const JSSThemeAuthForm10: JSSThemeDefaultAuthFormType = (themeVars) => {
  const {
    margins,
    borderRadius,
    colors: {
      colorTypes: { accent, primary, white, text1 },
    },
  } = themeVars;
  return {
    AuthFormDesktop: {
      depositWrap: {
        padding: [margins.xs, margins.md],
        background: accent,
        borderRadius: borderRadius.md,
        marginRight: margins.xs,
        fontWeight: 'bold',
        transition: '.3s ease-in',
        '&:hover': {
          background: primary,
        },
      },
    },
    DepositButton: {
      link: {
        '&:hover': {
          opacity: 1,
        },
      },
    },
    LoginFormDesktop: {
      forgotText: {
        opacity: 0.7,
      },
      popUpWrapper: {
        color: white,
        background: text1,
        '&:before': {
          background: text1,
        },
      },
    },
    PasswordResetWithEmail: {
      contactUsLink: {
        color: white,
        '&:hover': {
          color: white,
        },
      },
      passwordResetDebounce: {
        color: 'inherit',
        '@media (pointer: coarse)': {
          color: 'inherit',
        },
      },
    },
  };
};
