// @flow
import { type JSSThemeDefaultPopularLeaguesType } from '../JSSThemeDefaultPopularLeagues';

export const JSSThemePopularLeagues10: JSSThemeDefaultPopularLeaguesType = themeVars => {
  const {
    colors: {
      colorTypes: { background4, white },
    },
    fontSizes,
  } = themeVars;
  return {
    PopularLeagues: {
      root: {
        background: background4,
      },
      header: {
        fontSize: fontSizes.xxl,
      },
      img: {
        '@media (pointer: coarse)': {
          width: '14px',
          height: '14px',
        },
      },
      link: {
        '&:focus, &:active': {
          color: white,
        },
      },
    },
  };
};
