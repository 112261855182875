// @flow
import { type JSSThemeDefaultBetslipType } from '../JSSThemeDefaultBetslip';

export const JSSThemeBetslip10: JSSThemeDefaultBetslipType = themeVars => {
  const {
    margins,
    colors: {
      colorCombinations: { whiteError, text1Bg3 },
      colorTypes: { background2, white, background1, text1 },
    },
  } = themeVars;
  return {
    Betslip: {
      root: {
        background: background1,
      },
      info: {},
      wrapTotals: {},
      linkButtons: {
        margin: 0,
        padding: [margins.sm, margins.md],
      },
    },
    BetslipTotals: {
      root: {
        borderTop: `1px solid ${background2}`,
      },
      container: {
        color: white,
      },
    },
    BetslipSelection: {
      common: {
        background: white,
      },
    },
    NonSingleBody: {
      selectionInfo: {
        color: text1,
      },
    },
    TextPair: {
      row: {
        color: text1,
      },
    },
    SingleSelectionInfo: {
      eventDescription: {
        color: text1,
      },
      marketDescription: {
        color: text1,
      },
      priceDescription: {
        color: text1,
      },
    },
    SingleSelectionStaticInfo: {
      root: {
        background: white,
        color: text1,
      },
      marketDescription: {
        color: text1,
      },
    },
    BetslipInput: {
      container: {
        background: background1,
      },
      label: {
        color: white,
      },
    },
    BetslipErrors: {
      list: {
        ...whiteError,
      },
      listItem: {},
    },

    Receipt: {
      root: {
        background: background1,
      },
      controls: {
        background: background1,
      },
      titleContainer: {
        ...text1Bg3,
      },
    },
    ReceiptNonSingleSelection: {
      systemTitle: {
        color: white,
      },
    },
    SystemType: {
      amount: {
        color: white,
      },
    },
    EachWayButton: {
      label: {
        '&:before': {
          border: `1px solid ${text1}`,
        },
      },
    },
  };
};
