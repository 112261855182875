// @flow
import { type JSSThemeDefaultOutcomesType } from '../JSSThemeDefaultOutcomes';

export const JSSThemeOutcomes10: JSSThemeDefaultOutcomesType = themeVars => {
  const {
    colors: {
      colorTypes: { primary, background1, white },
    },
  } = themeVars;
  return {
    MobileOutcome: {
      root: {
        background: primary,
        color: '#fff',
        '&.active': {
          background: background1,
        },
        '&.suspended': {
          opacity: 0.4,
        },
      },
      // wideRoot: {
      //   background: border1,
      // },
      priceValue: {
        color: '#fff',
      },
    },
    Outcome: {
      trendUp: {
        color: white,
      },
      root: {
        color: '#fff',
        background: primary,
        '&.active': {
          background: background1,
        },
        '@media not all and (pointer: coarse)': {
          '&:hover': {
            background: background1,
          },
        },
      },
    },
  };
};
