// @flow
import { type JSSThemeDefaultSitemapType } from '../JSSThemeDefaultSitemap';

export const JSSThemeSitemap10: JSSThemeDefaultSitemapType = themeVars => {
  const {
    colors: {
      colorTypes: { text1 },
    },
  } = themeVars;
  return {
    sitemapTitle: {
      color: text1,
    },
    firstLinkLevel: {
      color: text1,
    },
    secondLinkLevel: {
      color: text1,
    },
    thirdLinkLevel: {
      color: ' rgba(0, 0, 0, 0.72)',
      fontSize: 15,
      fontWeight: 'normal',
      listStyle: 'none',
      '&:hover': {
        color: text1,
      },
    },
  };
};
