// @flow
import { type JSSThemeDefaultCasinoType } from '../JSSThemeDefaultCasino';
import { applyOnlyMobile, applyOnlyDesktop } from '../jss-helpers';

export const JSSThemeCasino10: JSSThemeDefaultCasinoType = (themeVars) => {
  const {
    margins,
    colors: {
      colorTypes: { white, text1, neutral1, background1, primary },
      colorCombinations: { whitePrimary },
    },
  } = themeVars;
  return {
    CasinoGameCategories: {
      gameCategories: {
        height: 'auto',
        minHeight: 60,
        position: 'static',
        background: text1,
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
          display: 'block',
          height: 15,
          background: '#F0F0F0',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#CECECE',
        },
        '&::-webkit-scrollbar-button:horizontal': {
          backgroundRepeat: 'no-repeat',
          backgroundSize: '50%',
          backgroundPosition: 'center',
        },
        '&::-webkit-scrollbar-button:horizontal:decrement': {
          backgroundImage:
            'url(https://offsidegaming.s3.amazonaws.com/image/subway_up-arrow.svg)',
        },
        '&::-webkit-scrollbar-button:horizontal:increment': {
          backgroundImage:
            'url(https://offsidegaming.s3.amazonaws.com/image/subway_up-arrow%20%281%29.svg)',
        },
        '&::-webkit-scrollbar-button:horizontal:decrement:end': {
          display: 'none',
        },
        '&::-webkit-scrollbar-button:horizontal:increment:start': {
          display: 'none',
        },
        '&::-webkit-scrollbar-button:start:no-button': {
          backgroundSize: 'cover',
        },
      },
      menuItem: {
        color: white,
        borderRight: 'none',
        '&.active, &:hover': {
          ...whitePrimary,
        },
        ...applyOnlyDesktop({
          '&:hover': {
            ...whitePrimary,
          },
        }),
        ...applyOnlyMobile({
          '&:hover, &:active': {
            color: white,
          },
        }),
      },
      iconChevron: {
        marginLeft: 5,
        '&.active': {
          transform: 'rotateZ(90deg) translateX(1px) translateY(-2px)',
        },
      },
    },
    SearchBox: {
      inputWrap: {
        display: 'flex',
        alignItems: 'center',
      },
      inputSearch: {
        height: 32,
        // background: white,
        borderBottom: 'none',
        marginRight: margins.md,
        color: white,
        '&::placeholder': {
          color: neutral1,
        },
      },
      iconSearch: {
        color: neutral1,
        right: 5,
      },
      searchResult: {
        padding: [0, margins.md],
      },
    },
    CasinoContainer: {
      providersFiltersWrap: {
        background: primary,
        padding: [margins.md],
      },
      providersFilters: {
        color: white,
        background: primary,
        '&.active': {
          borderColor: white,
          background: white,
          color: primary,
        },
        '@media not all and (pointer: coarse)': {
          '&:hover': {
            color: primary,
            background: white,
          },
        },
      },
    },
    CasinoGameLink: {
      rootReal: {
        fontWeight: 'bold',
      },
      rootFun: {
        background: background1,
      },
    },
  };
};
