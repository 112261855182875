// @flow
import { type JSSThemeDefaultHeaderMobileType } from '../JSSThemeDefaultHeaderMobile';

export const JSSThemeHeaderMobile10: JSSThemeDefaultHeaderMobileType = themeVars => {
  const {
    margins,
    colors: {
      colorCombinations: { whiteText1 },
      colorTypes: { primary, white },
    },
  } = themeVars;
  return {
    HeaderMobile: {
      headerContainer: {
        background: primary,
        color: white,
      },
      menuContainer: {
        ...whiteText1,
      },
      logoWrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: [margins.xs, 0],
      },
      logoImg: {
        // maxWidth: '140px',
      },
      iconContainer: {
        color: white,
      },
    },
  };
};
