// @flow
import { type JSSThemeDefaultLiveType } from '../JSSThemeDefaultLive';

export const JSSThemeLive10: JSSThemeDefaultLiveType = themeVars => {
  return {
    LiveMenuEvent: {
      LiveEventRanked: {
        background: 'white',
      },
    },
  };
};
