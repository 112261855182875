// @flow
import { type JSSThemeDefaultBalanceType } from '../JSSThemeDefaultBalance';

export const JSSThemeBalance10: JSSThemeDefaultBalanceType = themeVars => {
  const {
    margins,

    colors: {
      colorCombinations: { whitePrimary, text1Bg2 },
      colorTypes: { text1, white, primary },
    },
  } = themeVars;
  return {
    BalanceComponent: {
      titleContent: {
        color: white,
      },
      userMenuWrap: {
        padding: 0,
        background: text1,
        '&:before': {
          background: text1,
        },
      },
      accountMenuWrap: {
        borderBottom: 'none',
        borderTop: 'none',
      },
      balanceWrap: {
        display: 'flex',
        justifyContent: 'center',
      },
      logoutWrap: {
        color: white,
        fontWeight: 'bold',
        padding: margins.md,
        opacity: 0.6,
        '&:hover': {
          opacity: 1,
        },
      },
    },

    UsernameCompanent: {
      userName: {
        paddingTop: margins.md,
        color: white,
        opacity: 0.5,
        textAlign: 'center',
      },
      accountNumber: {
        color: white,
        opacity: 0.5,
        textAlign: 'center',
      },
    },
    Bonus: {
      bonusWrap: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        flex: 1,
      },
      bonusTitle: {
        color: white,
      },
      bonusValue: {
        color: primary,
      },
      bonusWrapMobile: {
        display: 'flex',
        alignItems: 'center',
      },
      bonusTitleMobile: {
        fontWeight: 300,
      },
      bonusValueMobile: {
        color: primary,
        fontWeight: 'bold',
        marginLeft: margins.md,
      },
    },
    AccountPageMenu: {
      userMenuItem: {
        padding: [margins.md],
        color: white,
        fontWeight: 300,
        '&:hover': {
          ...whitePrimary,
        },
      },
    },
    AccountPageMenuDesktop: {
      userMenu: {
        height: '100%',
      },
      userMenuItem: {
        color: white,
        padding: [margins.xm, margins.lg],
        '&.active, &:hover': {
          ...text1Bg2,
        },
      },
    },
  };
};
