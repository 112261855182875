// @flow
import { type JSSThemeDefaultSearchType } from '../JSSThemeDefaultSearch';

export const JSSThemeSearch10: JSSThemeDefaultSearchType = themeVars => {
  const {
    colors: {
      colorTypes: { text1, background2, primary },
    },
  } = themeVars;

  return {
    SportsbookSearchResult: {
      title: {
        '&.mobile': {
          color: '#fff',
        },
      },
      description: {
        color: text1,
        '&:hover': {
          color: text1,
          opacity: 0.5,
        },
      },
      marketCount: {
        background: background2,
        fontWeight: 'bold',
        color: '#fff',
        '&:hover': {
          background: primary,
        },
      },
    },
  };
};
