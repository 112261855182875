// @flow
import { LoadableComponentWrapper } from '../../components/components/LoadableComponentWrapper';
import { createKoltronApp } from '../createKoltronApp';
import {
  JSSTheme10,
  JSSThemeVariables10,
  getJSSThemePatterns10,
} from './JSSTheme10';
import { GeoblockPage10 } from './GeoblockPage10';
// $FlowIgnore
import '../../../styles/application-koltron.scss';

createKoltronApp({
  DesktopComponent: LoadableComponentWrapper({
    loader: () =>
      import('./FullDesktop10').then(module => module.FullDesktop10),
  }),
  MobileComponent: LoadableComponentWrapper({
    loader: () => import('./FullMobile10').then(module => module.FullMobile10),
  }),
  JSSTheme: JSSTheme10,
  JSSThemeVariables: JSSThemeVariables10,
  JSSThemePatterns: getJSSThemePatterns10,
  GeoblockPage: GeoblockPage10,
});
