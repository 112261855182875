// @flow
import { type JSSThemeDefaultFormType } from '../JSSThemeDefaultForm';

export const JSSThemeForm10: JSSThemeDefaultFormType = themeVars => {
  const {
    colors: {
      colorCombinations: { text1White },
      colorTypes: { text1, background1, black },
    },
  } = themeVars;
  return {
    PasswordField: {
      iconShowPassword: {
        color: black,
      },
    },
    Input: {
      root: {
        border: `1px solid ${text1}`,
        ...text1White,
        '&::placeholder': {
          color: text1,
        },
      },
      betslipInput: {
        ...text1White,
        border: `1px solid ${background1}`,
      },
    },
  };
};
